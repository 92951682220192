<template>
  <v-container class="setting">
    <v-row>
      <v-col cols="12" justify="center" align="center" class="mt-5">
        <v-avatar size="96px" style="border-radius: 0px">
          <img alt="Avatar" :src="profile.avatar || '/logo5.png'" />
        </v-avatar>
        <choose-file v-model="profile.avatar" />

        <h2 class="text-center mb-2">{{ profile.fullName }}</h2>
      </v-col>
      <v-col cols="12" justify="center" align="center" class="pl-3 pr-3">
        <v-form v-model="valid" @submit.prevent="updateProfile">
          <v-text-field
            v-model="profile.fullName"
            :label="$t('user.fullName')"
            filled
            outlined
            color="grey lighten-1"
            required
            :rules="nameRules"
            hide-details
          ></v-text-field>
          <v-row>
            <v-col>
              <v-select
                v-model="profile.gender"
                :label="$t('user.gender')"
                filled
                outlined
                color="grey lighten-1"
                required
                :items="genderOptions"
                hide-details
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                v-model="patient.birthdate"
                :label="$t('user.dob')"
                filled
                outlined
                color="grey lighten-1"
                hide-details
                v-mask="'##/##/####'"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-text-field
            v-model="patient.identityId"
            :label="$t('user.identityId')"
            filled
            outlined
            color="grey lighten-1"
            hide-details
          ></v-text-field>
          <v-text-field
            v-model="patient.address"
            :label="$t('user.address')"
            filled
            outlined
            color="grey lighten-1"
            hide-details
          ></v-text-field>

          <v-btn
            type="submit"
            color="blue-grey"
            block
            class="btn_login mt-5 mb-2 text-uppercase"
            :loading="loading"
            :disabled="!valid"
            style="margin-top: 15px"
          >
            {{ $t("common.save") }}
          </v-btn>
          <v-btn
            block
            class="mb-5 text-uppercase"
            style="margin-top: 15px"
            @click="$refs.PopupPersonalQr.confirm()"
          >
            {{ $t("common.showQr") }}
          </v-btn>
          <v-select
            v-model="language"
            :label="$t('common.chooseLanguage')"
            filled
            outlined
            required
            hide-details
            :items="languageOptions"
            @change="onChangeLanguage"
          ></v-select>
          <v-btn
            v-if="!isSyncing"
            block
            class="mb-5 text-uppercase"
            style="margin-top: 15px"
            @click="syncDataFromServer"
          >
            {{ $t("common.sync") }}
          </v-btn>
          <v-progress-linear
            v-else
            v-model="syncPercent"
            height="25"
            color="light-blue"
            class="mb-2"
            rounded
            :disabled="true"
          >
            <template v-slot:default="{ value }">
              <strong style="color: #e0e0e0">{{ Math.ceil(value) }} %</strong>
              <strong style="color: #e0e0e0; font-size: 0.8rem" class="ml-3"
                >( {{ done }} / {{ totals }} )
              </strong>
            </template>
          </v-progress-linear>
          <v-btn
            color="red"
            block
            class="btn_login mt-5 mb-2 text-uppercase"
            style="margin-top: 15px; background-color: #ef5350 !important"
            @click="logout"
          >
            {{ $t("user.logout") }}
          </v-btn>
        </v-form>
        <span style="font-size: 80%">App version 1.10.22</span>
      </v-col>
    </v-row>
    <popup-personal-qr ref="PopupPersonalQr" />
  </v-container>
</template>

<script>
import { GenderType } from "@/plugins/constants";
import ChooseFile from "@/components/ChooseFile.vue";
import PopupPersonalQr from "@/components/PopupPersonalQr.vue";
import { getAppLanguage } from "@/lang/i18n";
import axios from "axios";

import { getLocalStorage, removeLocalStorage } from "@/plugins/helpers";
import { cfaSignOut } from "capacitor-firebase-auth";

export default {
  components: {
    ChooseFile,
    PopupPersonalQr,
  },
  computed: {
    genderOptions() {
      return Object.keys(GenderType).map((key) => ({
        text: this.$t(`register.${key.toLowerCase()}`),
        value: GenderType[key],
      }));
    },
    languageOptions() {
      return [
        { text: "English", value: "en" },
        { text: "Việt Nam", value: "vn" },
      ];
    },
  },
  data() {
    return {
      loading: false,
      valid: false,
      id: this.$store.getters["Authen/getUser"].id,
      profile: {
        fullName: this.$store.getters["Authen/getUser"].fullName,
        avatar: this.$store.getters["Authen/getUser"].avatar,
        gender: this.$store.getters["Authen/getUser"].gender,
      },
      patient: {
        address: "",
        birthdate: "",
        identityId: "",
      },
      language: getAppLanguage(),
      nameRules: [
        (v) => !!v || "Name is required",
        (v) =>
          (!!v && v.length >= 6) || "Name must be bigger than 6 characters",
      ],
      isSyncing: false,
      syncPercent: 0,
      done: 0,
      totals: 0,
    };
  },
  mounted() {
    this.getProfile();
    this.listeningSyncingEvent();
  },
  methods: {
    async logout() {
      var deviceToken = getLocalStorage("FcmDeviceToken");
      if (deviceToken) {
        await this.$dbRemove(`deviceToken/${this.$uid}/${deviceToken}`);
        removeLocalStorage("FcmDeviceToken");
      }
      cfaSignOut().subscribe();
      this.$store.commit("Authen/LOGOUT");
      await this.sleep(200);
      this.$router.go("/login");
    },
    async getProfile() {
      if (!this.id) return;
      if (!this.$phone) return;
      this.profile = {
        fullName: await this.$dbGet(`users/${this.id}/fullName`),
        gender: await this.$dbGet(`users/${this.id}/gender`),
        avatar: await this.$dbGet(`users/${this.id}/avatar`),
      };
      var patient = await this.$dbGet(`patients/${this.$phone}`);
      this.patient = { ...patient, ...this.profile };
    },
    async updateProfile() {
      if (!this.id) return;
      this.loading = true;

      await this.$dbSet(`users/${this.id}/fullName`, this.profile.fullName);
      await this.$dbSet(`users/${this.id}/gender`, this.profile.gender);
      await this.$dbSet(`users/${this.id}/avatar`, this.profile.avatar);

      await this.$dbSet(`patients/${this.$phone}`, this.patient);

      var user = this.$store.getters["Authen/getUser"];
      user = { ...user, ...this.profile };
      this.$store.commit("Authen/SET_USER_PROFILE", user);
      await this.sleep(200);
      await this.ensurePatientProfileExist();
      this.loading = false;
      this.showSuccess(this.$t("common.successMessage"));
    },
    async onChangeLanguage() {
      var language = this.language;
      this.$root.$i18n.locale = language;
      localStorage.setItem("BLE_APP_DEFAULT_LANGUAGE", language);
    },
    async syncDataFromServer() {
      this.isSyncing = true;
      var result = await axios.post(
        "https://us-central1-sandrasoft-8fe2b.cloudfunctions.net/syncDatapointsFromServer",
        {},
        { headers: { phone: this.$phone, uid: this.$uid } }
      );
      if (!result || !result.totals) this.isSyncing = false;
    },
    listeningSyncingEvent() {
      this.$dbWatcher(`syncing/${this.$uid}`, async (obj) => {
        if (!obj) {
          this.syncPercent = 0;
          this.isSyncing = false;
          this.done = 0;
          this.totals = 0;
          return;
        }
        this.isSyncing = true;
        var { done, totals } = obj;
        this.syncPercent = Math.floor((done / totals) * 100);
        this.done = done;
        this.totals = totals;
      });
    },
  },
};
</script>

<style lang="scss">
.setting {
  color: #e0e0e0;
  @media screen and (min-width: 820px) {
    background-color: rgba(30, 32, 55, 0.12);
  }
  img.logo {
    height: 5.8rem;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
  h1.page_title {
    font-size: 1.65rem;
    font-weight: 500;
    letter-spacing: 1.5px;
    width: 100%;
    display: block;
    text-align: center;
    text-transform: capitalize;
  }
  .v-btn {
    text-transform: capitalize;
    width: 130px;
    background-color: rgba(24, 25, 46, 0.65) !important;
    color: #e0e0e0;
    height: 42px !important;
    border-radius: 6px;
    font-weight: 300;
    font-size: 13px;
  }
  .btn_login {
    height: 50px !important;
    line-height: 50px !important;
    width: calc(100% - 24px) !important;
    background-color: rgb(0, 128, 227) !important;
    font-weight: 400;
    font-size: 15px;
  }
  .v-btn.btn_login.v-btn--disabled {
    background-color: rgb(0, 128, 227) !important;
    opacity: 0.8;
  }
  .v-divider {
    border-color: #37474f;
  }
  .label_or {
    margin-top: -10px;
    display: block;
    width: 50px;
    background-color: rgb(30, 32, 55);
    font-size: 13px;
    border-radius: 8px;
  }
  .v-input {
    margin-bottom: 15px;
    .v-input__slot {
      background-color: rgba(24, 25, 46, 0.45) !important;
      border-radius: 6px;
      .theme--light.v-label {
        color: #e0e0e0 !important;
      }
      input {
        color: #e0e0e0 !important;
        margin-top: 0px !important;
      }
      .select__selection {
        color: #e0e0e0;
      }
    }
  }
  .v-select__selections {
    color: #e0e0e0 !important;
  }
  span.bottom {
    font-size: 15px;
  }
  label.label_title {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
    font-size: 120%;
  }
  .verify-code {
    text-align: center;
    font-size: 2rem;
    letter-spacing: 4px;
  }
  .v-btn--disabled {
    background-color: rgba(0, 0, 0, 0.5) !important;
    color: #e0e0e0 !important;
    opacity: 0.7;
  }
  .btn-upload {
    height: 30px !important;
    font-size: 13px;
  }
}
</style>
